//非运营类型
export function typeNonOperational() {
    const typeNonOperationalList = [
        {
            value: "1",
            label: "加油申请"
        },
        {
            value: "2",
            label: "维修申请"
        },
        {
            value: "3",
            label: "维修出场"
        },
        {
            value: "4",
            label: "首班出场"
        },
        {
            value: "5",
            label: "中间回场"
        },
        {
            value: "6",
            label: "中间出场"
        },
        {
            value: "7",
            label: "末班回场"
        },
        {
            value: "10",
            label: "充电"
        },
        {
            value: "11",
            label: "包车"
        },
        {
            value: "8",
            label: "其他申请"
        },
        {
            value: "9",
            label: "其他出场"
        }
    ];
    return typeNonOperationalList;
}

//路单审核状态
export function getAuditStatus() {
    let list = [
        {
            value: "0",
            label: "待审核"
        },
        {
            value: "2",
            label: "审核通过"
        },
        {
            value: "3",
            label: "审核不通过"
        },
        {
            value: "1",
            label: "系统自动审核"
        }
    ];
    return list;
}

//运行情况
export function getRunStatus() {
    let list = [
        // {
        // 	value: '0',
        // 	label: '未发车'
        // },
        {
            value: "1",
            label: "已发车"
        },
        {
            value: "2",
            label: "已完成"
        },
        {
            value: "4",
            label: "异常订单状态"
        }
    ];
    return list;
}
//方案类型
export function getSchemeTs() {
    let list = [
        {
            value: "1",
            label: "上行"
        },
        {
            value: "2",
            label: "下行"
        },
        {
            value: "3",
            label: "环形"
        }
    ];
    return list;
}

export function deviceType() {
    let list = [
        {
            value: 0,
            label: "主动安全"
        },
        {
            value: 1,
            label: "GB32960"
        },
        {
            value: 2,
            label: "DVR"
        },
        {
            value: 3,
            label: "客流"
        },
        {
            value: 4,
            label: "调度"
        }
    ];
    return list;
}

export function getRType() {
    let list = [
        {
            value: "0",
            label: "计划班次"
        },
        {
            value: "1",
            label: "非计划班次"
        }
    ];
    return list;
}

export function getFuelType() {
    let list = [
        {
            value: "0",
            label: "汽油"
        },
        {
            value: "1",
            label: "柴油"
        },
        {
            value: "2",
            label: "混合动力"
        },
        {
            value: "3",
            label: "纯电动"
        }
    ];
    return list;
}

export function getShiftType() {
    let list = [
        {
            value: "1",
            label: "普通班"
        },
        {
            value: "2",
            label: "加班"
        },
        {
            value: "3",
            label: "夜班"
        }
    ];
    return list;
}

const snapChannelTypeList = [
    {
        value: "通道1",
        code: "01"
    },
    {
        value: "通道2",
        code: "02"
    },
    {
        value: "通道3",
        code: "03"
    },
    {
        value: "通道4",
        code: "04"
    },
    {
        value: "通道5",
        code: "05"
    },
    {
        value: "通道6",
        code: "06"
    },
    {
        value: "通道7",
        code: "07"
    },
    {
        value: "通道8",
        code: "08"
    },
    {
        value: "通道9",
        code: "09"
    }
];

export function getSnapChannelTypeList() {
    return snapChannelTypeList;
}

export function getStationTypeList() {
    return [
        {
            text: "停车场站",
            value: "0"
        },
        {
            text: "维修场站",
            value: "1"
        },
        {
            text: "充电场站",
            value: "2"
        },
        {
            text: "加油场站",
            value: "3"
        }
    ];
}

export function getScaleTypeList() {
    return [
        {
            text: "主场站",
            value: "0"
        },
        {
            text: "副场站",
            value: "1"
        }
    ];
}

//业务请求类型
export function dreportType() {
    return [
        {
            label: "包车",
            value: "1"
        },
        {
            label: "加油",
            value: "2"
        },
        {
            label: "维修",
            value: "3"
        }
    ];
}

export default {};
