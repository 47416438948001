<template>
    <div>
        <jy-query ref="checkForm" :model="formData">
            <jy-query-item label="业务请求类型" prop="rType" :span="1.4">
                <el-select v-model="formData.rType" placeholder="请选择">
                    <el-option v-for="item in dreportTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="线路" prop="routeIds" :span="1.4">
                <el-select v-model="formData.routeIds" placeholder="请选择" multiple>
                    <el-option v-for="item in routeList" :key="item.routeId" :label="item.cName" :value="item.routeId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="车牌号" prop="routeIds" :span="1.4">
                <el-input v-model="formData.plateNum"></el-input>
            </jy-query-item>
            <jy-query-item label="开始时间:" prop="startTime" >
                <el-date-picker v-model="formData.startTime" value-format="yyyy-MM-dd" :picker-options="startTime" type="date" placeholder="选择日期"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="结束时间:" prop="endTime" >
                <el-date-picker v-model="formData.endTime" value-format="yyyy-MM-dd" :picker-options="endTime" type="date" placeholder="选择日期"></el-date-picker>
            </jy-query-item>
            <template #rightCol>
                <el-button type="primary" v-on:click="search" >查询</el-button>
                <el-button type="primary" v-on:click="reset" >重置</el-button>
                <!-- <el-button type="primary" v-on:click="search" v-if="btnexist('')">查询</el-button>
                <el-button type="primary" v-on:click="reset" v-if="true||btnexist('')">重置</el-button> -->
            </template>
        </jy-query>
        <jy-table :data="dataList">
            <jy-table-column type="rTime" label="请求时间" width="60"></jy-table-column>
            <jy-table-column label="业务请求类型">
                <template slot-scope="scope">{{ scope.row.rType | rType }}</template>
            </jy-table-column>
            <jy-table-column prop="routeNa"  label="线路"></jy-table-column>
            <jy-table-column prop="plateNum" label="车牌号码"></jy-table-column>
            <jy-table-column prop="driverNa" label="驾驶员"></jy-table-column>
            <jy-table-column prop="statue" label="审核状态"></jy-table-column>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
    </div>
</template>
<script>
import { dreportType } from "@/common/js/wordbook.js";
export default {
    data() {
        return {
            formData: {
                rType:'',
                plateNum: '',
                startTime: '',
                endTime: '',
                routeIds: []
            },
            dreportTypeList: [],
            dataList: [],
            routeList: [],
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            startTime: {
                disabledDate: time => {
                    if (this.formData.endTime) {
                        return (
                            time.getTime() >= new Date(this.formData.endTime).getTime()
                        );
                    } else {
                        return time.getTime() > Date.now();
                    }
                }
            },
            endTime: {
                disabledDate: time => {
                    if (this.formData.startTime) {
                        return (
                            time.getTime() > Date.now() ||
                            time.getTime() < new Date(this.formData.startTime).getTime() -8.64e7
                        );
                    } else {
                        return time.getTime() > Date.now();
                    }
                }
            },
        }
    },
    filters: {
        rType(val) {
            let v = ''
            switch (val) {
                case '1':
                    v = '包车'
                    break
                case '2':
                    v = '加油'
                    break
                case '3':
                    v = '维修'
                    break
            }
            return v
        }
    },
    created() {
        this.dreportTypeList = dreportType()
        this.getRouteList()
    },
    methods: {
        reset() {
            this.$refs.checkForm.resetFields()
            this.getList()
        },
        search() {
            this.getList()
        },
        getList() {
            let option = {
                ...this.formData,
                pageIndex:this.pageIndex,
                pageSize:this.pageSize
            }
            let url = '/dreport/queryPage'
            this.$http.post(url, option).then(({ detail }) => {
                let list = detail.forEach(item => {
                    item.statue = "自动审核通过"
                    return {
                        ...item
                    }
                })
                this.dataList = list;
                this.total = detail.total
            })
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            this.$http.post(url).then(({ detail }) => {
                this.routeList = detail
            })
        },
    }
}

</script>
